import React from "react";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import UnorderedList from "~/components/List/Unordered";

const Page = () => {
  return (
      <LessonLayout
        current_step={0}
        lesson={"Lesson 3"}
        color={"ms"}
        total_count={10}
        module_title="Will You Buy What They Are Selling?"
      >
        <ContentWrapper>
          <H2 underline>Step 0 (teacher only)</H2>

          <p>Before students log onto the computer, have a quick class discussion about the question, "Why do people put health information on the internet?"</p>

          <p>This should take only a minute or two. Student answers will vary. Likely responses may include the following:</p>

          <UnorderedList>
            <li>To educate people about medical problems</li>
            <li>To promote good health</li>
            <li>To sell a health-related product or service</li>
            <li>To try to persuade people about a health-related issue</li>
          </UnorderedList>

          <p>Students will start working on the computers in the next step.</p>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;

